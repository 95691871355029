import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import * as pages from './pages';
import * as pagesV2 from './v2/pages';
import TopBar from './components/TopBar';
import { AppStore, Settings } from './state';
import BottomBar from './components/BottomBar';
import { SnackbarProvider } from 'notistack';
import React, { RefObject } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { css, Global } from '@emotion/react';
import { siteData } from './util';

function App() {
  let isRegister = useRouteMatch('/register');
  let isResetPassword = useRouteMatch('/reset_password');
  isRegister = isRegister || isResetPassword;

  let [auth, loading, loadingSettings, promptRefresh, waitingVerification] =
    AppStore.useState((s) => [
      s.auth,
      s.loading,
      s.loadingSettings,
      s.promptRefresh,
      s.waitingVerification,
    ]);
  let { settings, competitionList } = AppStore.useState((s) => s);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const next = params.get('next');

  if (loading || loadingSettings) return null;

  if (!auth && !isRegister) {
    return (
      <>
        <Global styles={AppCss({ settings })} />
        <pages.Login2FA />
      </>
    );
  }

  if (waitingVerification !== undefined && !isRegister) {
    return (
      <>
        <Global styles={AppCss({ settings })} />
        <pages.WaitingVerification />
      </>
    );
  }

  if (next !== null) {
    // in case the ?next=/admin/ override window location
    window.location.href = `${window.location.protocol}//${window.location.hostname}${next}`;
    return null;
  }

  // add action to all snackbars
  const notistackRef: RefObject<SnackbarProvider> = React.createRef();

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(snackbarId: any) => (
        <IconButton
          onClick={() => notistackRef.current?.closeSnackbar(snackbarId)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    >
      <Global styles={AppCss({ settings })} />
      <div>
        <TopBar
          competitions={competitionList?.data || []}
          hasMenu={!isRegister}
        />
        {promptRefresh && (
          <PromptRefresh onClick={promptRefresh} settings={settings}>
            The app has been updated.
            <br />
            Please click here to reload.
          </PromptRefresh>
        )}
        <Switch>
          <Route
            path="/competition/:competition_id"
            component={pagesV2.CompetitionPages}
          />

          <Route exact path="/" component={pages.Home} />
          <Route exact path="/page/:slug" component={pages.Page} />
          <Route exact path='/dpage/:slug' component={pages.DynamicPage}/>
          <Route exact path="/rules" component={pages.CompetitionRules} />
          <Route exact path="/settings" component={pages.Settings} />
          <Route exact path="/register" component={pages.Register} />
          <Route exact path="/reset_password" component={pages.ResetPassword} />
          {siteData().has_arena && (
            <>
              <Route exact path="/arena" component={pages.WallFeed} />
              <Route exact path="/arena/edit" component={pages.EditPost} />
            </>
          )}
        </Switch>
        <BottomBar />
      </div>
    </SnackbarProvider>
  );
}

interface AppCssProps {
  settings: Settings;
}

let PromptRefresh = styled.div<AppCssProps>`
  position: fixed;
  top: 80px;
  text-align: center;
  padding: 10px;
  line-height: 1.2;
  border-radius: 10px;
  width: 250px;
  left: 50%;
  transform: translate(-50%);
  height: auto;
  z-index: 300;
  background: ${(props) => props.settings.main_color};
  color: ${(props) => props.settings.main_text_color};
  opacity: 0.95;
  cursor: pointer;
`;

const AppCss = (props: AppCssProps) => css`
  body {
    height: 100%;
  }

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  /*
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
*/

  html,
  body {
    font-family: ${props.settings.main_font}, sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${props.settings.main_font}, sans-serif;
    font-weight: normal;
  }

  #root {
    position: relative;
    min-width: 320px;
    max-width: 600px;
    margin: 0 auto;
    height: 100%;
  }

  #root > div {
    height: 100%;
  }

  #root > div > div {
    font-size: 0.75rem;
  }

  @media (hover: none) {
    .MuiButton-containedPrimary:hover {
      background-color: ${props.settings.main_color} !important;
      color: ${props.settings.main_text_color} !important;
    }
  }

  .MuiButton-containedPrimary {
    background-color: ${props.settings.main_color} !important;
    color: ${props.settings.main_text_color} !important;
  }

  .MuiButton-containedSecondary {
    background-color: green !important;
  }

  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 56px !important;
    }
  }

  @media (min-width: 0px) and (orientation: landscape) {
    .MuiToolbar-regular {
      min-height: 56px !important;
    }
  }

  .MuiPickersToolbar-toolbar {
    background-color: ${props.settings.main_color} !important;
    color: ${props.settings.main_text_color} !important;
  }

  .MuiPickersDay-daySelected {
    background-color: ${props.settings.main_color} !important;
    color: ${props.settings.main_text_color} !important;
  }

  .MuiButton-textPrimary {
    color: ${props.settings.main_color} !important;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.75rem !important;
  }

  .MuiSelect-select {
    font-size: 0.75rem !important;
  }

  .MuiMenuItem-root {
    font-size: 0.75rem !important;
  }

  .ptr__children {
    overflow: auto !important;
  }

  .rich-text {
    padding: 20px;
  }

  .rich-text p {
    margin-bottom: 20px;
  }

  .rich-text h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  .rich-text h2 {
    font-size: 1.8em;
    margin-bottom: 0.5em;
  }

  .rich-text h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }

  .menu .mantine-Modal-content {
    background-color: ${props.settings.main_color};
  }

  .menu .mantine-Modal-content > div {
    height: 100%;
  }

  .menu .mantine-Modal-body {
    padding: 0;
    height: 100%;
  }

  .menu .mantine-ScrollArea-root,
  .menu .mantine-ScrollArea-viewport > div {
    height: 100%;
  }
`;

export default App;
